import React from 'react'
import Layout from 'components/Layout'
import Link from 'components/Link'
import SectionUsers from 'components/SectionUsers'
import SectionIntegrations from 'components/SectionIntegrations'
import SectionGetStarted from 'components/SectionGetStarted'

class Homepage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      controls: null,
    }

    this.addControls = this.addControls.bind(this)
    this.removeControls = this.removeControls.bind(this)
  }

  addControls() {
    this.setState({ controls: 'controls' })
  }

  removeControls() {
    this.setState({ controls: null })
  }

  render() {
    return (
      <Layout
        title="UseData helps you make sense of your data"
        description="Use UseData to connect to any data source (Redshift, BigQuery, MySQL, PostgreSQL, MongoDB and many others), query, visualize and share your data to make your company data driven."
        location={this.props.location}
      >
        <section className="section section--hero">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <h1 className="mb-xs serif">
                  Manage your data as an <u>asset</u> across the whole business
                </h1>
                <h3 className="font-light">
                  Connect and correlate data from different sources, build data flows to
                  get it processed into the right systems and make analytical capabilities available to all key people within your company
                </h3>
                <Link
                  data-track
                  data-track-location="hero"
                  track-event="Clicked Get Started"
                  to="http://app.usedata.io/signup"
                  className="btn btn-primary btn-lg"
                >
                  Get Started
                </Link>
              </div>
              <div className="col-md-7 hidden-xs hidden-sm">
                <div className="browser-container hidden-xs m-t-50">
                  <img src="/assets/images/elements/home_dashboard.png" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <h2 className="text-center m-b-0">
              A Data Platform For Digital Transformation
            </h2>
            <p className="m-t-0 text-center mb-md">
              <i>Technology and infrastructure are enable organizations take actions from insights and create impact</i>
            </p>
            <div className="row">
              <div className="col-sm-4">
                <img
                  src="/assets/images/elements/data_platform.svg"
                  id="write-query-illustrated"
                  className="visible-xs text-center"
                />

                <p className="highlight-p">
                  A systematic approach maps a series of actions to translate data insights into business value:
                </p>

                <ol className="highlight-p">
                  <li> Query data from or update data to multiple data sources</li>
                  <li> Run queries by schedules to get latest results</li>
                  <li> Visualize query results by charts and share with others</li>
                  <li> Stream query results to other systems or services</li>
                </ol>
              </div>
              <div className="col-sm-8 hidden-xs">
                <img
                  src="/assets/images/elements/data_platform.svg"
                  id="write-query-illustrated3"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <h2 className="text-center m-b-0">
              Build Data Flows with AI Technologies
            </h2>            
            <p className="m-t-0 text-center mb-md">
              <i>Extract text from images using Google Vision API</i>
            </p>
            <div className="row m-t-50">
              <div className="col-sm-12 text-center">
                <img
                  src="/assets/images/elements/dataflows.svg"
                  id="visualize-illustrated2"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section section__more-features">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2 col-xs-12 p-0 flex-content-center flex">
                <div className="feature-container">
                  <img
                    src="/assets/images/elements/icon-api.png"
                    width="64px"
                  />
                  <h4>API</h4>
                </div>

                <div className="feature-container">
                  <img
                    src="/assets/images/elements/icon-alerts.png"
                    width="64px"
                  />
                  <h4>Group Monitoring</h4>
                </div>

                <div className="feature-container">
                  <img
                    src="/assets/images/elements/icon-user-management.png"
                    width="64px"
                  />
                  <h4>User Management</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-center">
                <br />
                <Link
                  data-track
                  data-track-location="features"
                  track-event="Clicked All UseData Features"
                  to="/product"
                  className="btn btn-secondary"
                >
                  All UseData Features
                </Link>
              </div>
            </div>
          </div>
        </section>

        <SectionIntegrations />

        <SectionGetStarted />
      </Layout>
    )
  }
}

export default Homepage
